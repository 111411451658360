<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Usuarios</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/Admin">Administrativo</router-link>
                  </li>
                  <li class="breadcrumb-item active">Seguridad</li>
                  <li class="breadcrumb-item active">Usuarios</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <h5 class="mb-0">Formulario {{ accion }} Usuarios</h5>
              </div>
              <div class="row p-3 d-flex justify-content-center">
                <fieldset class="well card col-md-9 m-1">
                  <legend class="well-legend text-bold bg-frontera text-light">
                    Datos Personales
                  </legend>
                  <div class="row d-flex justify-content-center">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="type_document">Tipo Documento</label>
                        <select class="form-control form-control-xl" v-model="form.type_document"
                          :class="$v.form.type_document.$invalid ? 'is-invalid' : 'is-valid'">
                          <option value="">Seleccione...</option>
                          <option v-for="tipo in listasForms.tipo_identificacion" :key="tipo.numeracion"
                            :value="tipo.numeracion">
                            {{ tipo.descripcion }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="n_documento">Numero Identificación</label>
                        <input type="number" class="form-control form-control-xl" id="n_documento"
                          :disabled="!form.type_document" v-model="form.n_documento"
                          :class="$v.form.n_documento.$invalid ? 'is-invalid' : 'is-valid'"
                          @change="documentValidacion()" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="name">Nombre</label>
                        <input type="text" class="form-control form-control-xl" id="name" v-model="form.nombres"
                          :class="$v.form.nombres.$invalid ? 'is-invalid' : 'is-valid'"
                          @input="validarLetra(form.nombres, 1)" @change="modificacion('nombres', 'Nombres')"
                          @keyup="actualizarSugerencias" @focus="campoActivo = 'nombres'" />
                        <ul v-if="sugerencias.length > 0 && form.nombres.length > 3 && campoActivo === 'nombres'"
                          class="suggestions-list">
                          <li v-for="sugerencia in filtrarSugerencias(form.nombres, 'nombres')" :key="sugerencia.nombre"
                            @click="seleccionarSugerencia('nombres', sugerencia.nombre)">
                            {{ sugerencia.nombre }}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="status">Apellidos</label>
                        <input type="text" class="form-control form-control-xl" id="status" v-model="form.apellidos"
                          :class="$v.form.apellidos.$invalid ? 'is-invalid' : 'is-valid'"
                          @input="validarLetra(form.apellidos, 1)" @change="modificacion('apellidos', 'Apellidos')"
                          @keyup="actualizarSugerencias" @focus="campoActivo = 'apellidos'" />
                        <ul v-if="sugerencias.length > 0 && form.apellidos.length > 3 && campoActivo === 'apellidos'"
                          class="suggestions-list">
                          <li v-for="sugerencia in filtrarSugerencias(form.apellidos, 'apellidos')"
                            :key="sugerencia.apellido" @click="seleccionarSugerencia('apellidos', sugerencia.apellido)">
                            {{ sugerencia.apellido }}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="email">Email</label>
                        <input type="email" class="form-control form-control-xl" id="email" v-model="form.email"
                          :class="$v.form.email.$invalid ? 'is-invalid' : 'is-valid'" @input="actualizarSugerencias"
                          @change="emailValidacion(), modificacion('email', 'Email')" @focus="campoActivo = 'email'" />
                        <ul v-if="sugerencias.length > 0 && form.email.length > 3 && campoActivo === 'email'"
                          class="suggestions-list">
                          <li v-for="sugerencia in filtrarSugerencias(form.email, 'email')" :key="sugerencia.email"
                            @click="seleccionarSugerencia('email', sugerencia.email)">
                            {{ sugerencia.email }}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="cel">Celular</label>
                        <input type="number" class="form-control form-control-xl" id="cel" v-model="form.telefono"
                          :class="$v.form.telefono.$invalid ? 'is-invalid' : 'is-valid'" @input="actualizarSugerencias"
                          @change="modificacion('telefono', 'Teléfono')" @focus="campoActivo = 'telefono'" />
                        <ul v-if="sugerencias.length > 0 && form.telefono && campoActivo === 'telefono'"
                          class="suggestions-list">
                          <li v-for="sugerencia in filtrarSugerencias(form.telefono, 'telefono')"
                            :key="sugerencia.telefono" @click="seleccionarSugerencia('telefono', sugerencia.telefono)">
                            {{ sugerencia.telefono }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </fieldset>
                <fieldset class="well card col-md-9 m-1">
                  <legend class="well-legend text-bold bg-frontera text-light">
                    Información general
                  </legend>
                  <div>
                    <div class="row d-flex justify-content-center">
                      <div class="col-md-12 mt-4">
                        <div class="form-group">
                          <div class=" alert alert-default-primary border border-primary">
                            <h5>
                              <i class="fas fa-question-circle"></i>
                              ¿Tiene contrato directo con frontera?
                            </h5>
                          </div>
                          <input type="radio" name="pregunta" id="si" value="si" v-model="rd" @input="checkRd">
                          <label for="si">Si</label><br>
                          <input type="radio" name="pregunta" id="no" value="no" v-model="rd" @input="checkRd">
                          <label for="no">No</label>
                        </div>
                      </div>
                      <div class="col-md-12" v-if="rd">
                        <div class="col-md-12">
                          <label for="cel">Nombre Empresa</label>
                          <input type="text" class="form-control form-control-xl" v-model="form.empresa"
                            :class="$v.form.empresa.$invalid ? 'is-invalid' : 'is-valid'" v-if="rd == 'si'"
                            @change="modificacion('empresa', 'Empresa')" />
                          <input type="text" class="form-control form-control-xl" v-model="form.empresa_tercero"
                            :class="$v.form.empresa_tercero.$invalid ? 'is-invalid' : 'is-valid'" v-else />
                        </div>
                        <div class="col-md-12 mt-4" v-if="rd == 'no'">
                          <div class=" alert alert-default-primary border border-primary">
                            <h5>
                              <i class="fas fa-question-circle"></i>
                              ¿A qué empresa representa, que tenga contrato directo con frontera?
                            </h5>
                          </div>
                          <input type="text" class="form-control form-control-xl" v-model="form.empresa"
                            :class="$v.form.empresa.$invalid ? 'is-invalid' : 'is-valid'"
                            @change="modificacion('empresa', 'Empresa')" />
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="form-group">
                            <label for="rol_cargo">Rol o Cargo</label>
                            <input type="text" class="form-control form-control-xl" v-model="form.rol_cargo"
                              :class="$v.form.rol_cargo.$invalid ? 'is-invalid' : 'is-valid'"
                              @input="actualizarSugerencias" @change="modificacion('rol_cargo', 'Cargo')"
                              @focus="campoActivo = 'rol_cargo'" />
                            <ul
                              v-if="sugerencias.length > 0 && form.rol_cargo.length > 3 && campoActivo === 'rol_cargo'"
                              class="suggestions-list">
                              <li v-for="sugerencia in filtrarSugerencias(form.rol_cargo, 'rol_cargo')"
                                :key="sugerencia.rol_cargo"
                                @click="seleccionarSugerencia('rol_cargo', sugerencia.rol_cargo)">
                                {{ sugerencia.rol_cargo }}
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="lineas">Tipo de operación y/o Línea de Negocio</label>
                            <p>Para evitar demoras en su solicitud, por favor seleccionar el tipo de operación u
                              operaciones que realmente se ajusten a su requerimiento.</p>
                            <div v-for="linea in listasForms.lineas" :key="linea.id">
                              <input type="checkbox" @change="validateLines" :value="linea.numeracion"
                                v-model="form.lineas" />
                              <label>{{ linea.descripcion }}</label>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="cel">No de contrato de frontera</label>
                            <input type="motivo" class="form-control form-control-xl" id="motivo"
                              v-model="form.n_contrato"
                              :class="$v.form.n_contrato.$invalid ? 'is-invalid' : 'is-valid'" />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="email_admin">Email Administrador Frontera</label>
                            <v-select :class="[$v.form.email_admin.$invalid ? 'is-invalid' : 'is-valid']"
                              class="form-control form-control-xl p-0" id="email_admin" v-model="admin"
                              :options="listasForms.funcionarios" label="nombres" @input="selectAdmin" />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="cel">Motivo</label>
                            <textarea name="area" rows="2" cols="40" class="form-control form-control-xl " id="motivo"
                              v-model="form.motivo"
                              :class="$v.form.motivo.$invalid ? 'is-invalid' : 'is-valid'"></textarea>
                          </div>
                        </div>
                        <div class="col-md-12 mt-5" v-if="modificaciones.length > 0 && alert">
                          <div class="form-group">
                            <div class="alert alert-default-warning border border-warning">
                              <h5>
                                <i class="fas fa-exclamation-triangle"></i>
                                Por favor digite el motivo de las siguientes actualizaciones:
                              </h5>
                              <ul>
                                <li v-for="(mod, index) in modificaciones" :key="index">{{ mod }}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button class="btn bg-primary col-md-1" v-show="!$v.form.$invalid && isValidSelection"
                    @click="confirmationModal()"
                    v-if="$store.getters.can('admin.solicitudUsuarios.create') || $store.getters.can('admin.solicitudUsuarios.edit')">
                    <i class="fas fa-paper-plane"></i><br />Guardar
                  </button>
                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {
  required,
  minLength,
  maxLength,
  email,
} from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
import vSelect from "vue-select";
export default {
  name: "UsuarioFronteraForm",
  components: {
    Loading,
    vSelect,
  },
  data() {
    return {
      form: {
        id: '',
        empresa: '',
        empresa_tercero: '',
        n_contrato: '',
        rol_cargo: '',
        email: '',
        email_admin: '',
        motivo: '',
        estado: '',
        nombres: '',
        apellidos: '',
        n_documento: '',
        type_document: '',
        telefono: '',
        linea_negocio_id: '',
        lineas: [],
      },
      error: "",
      isValidSelection: false,
      admin: [],
      accion: "",
      metodo: "",
      rd: "si",
      email: "",
      modificaciones: [],
      datosOriginales: [],
      sugerencias: [],
      alert: false,
      lineas_negocios: [],
      n_documento: "",
      listasForms: {
        tipo_identificacion: [],
        empresas: [],
        lineas: [],
        funcionarios: [],
      },
      id: this.$route.params.id,
      cargando: false,
    };
  },

  validations() {
    if (this.rd == 'si') {
      return {
        form: {
          n_documento: {
            required,
            minLength: minLength(7),
            maxLength: maxLength(13),
          },
          nombres: {
            required,
            minLength: minLength(3),
          },
          email: {
            required,
            email,
          },
          telefono: {
            required,
            minLength: minLength(10),
            maxLength: maxLength(10),
          },
          apellidos: {
            required,
            minLength: minLength(3),
          },
          empresa: {
            required,
          },
          rol_cargo: {
            required,
          },
          motivo: {
            required,
            minLength: minLength(10),
          },
          n_contrato: {
            required,
          },
          type_document: {
            required,
          },
          email_admin: {
            required,
          }
        },
      }
    } else if (this.rd == 'no') {
      return {
        form: {
          n_documento: {
            required,
            minLength: minLength(7),
            maxLength: maxLength(13),
          },
          nombres: {
            required,
            minLength: minLength(8),
          },
          email: {
            required,
            email,
          },
          telefono: {
            required,
            minLength: minLength(10),
            maxLength: maxLength(10),
          },
          apellidos: {
            required,
            minLength: minLength(8),
          },
          empresa: {
            required,
          },
          empresa_tercero: {
            required,
          },
          rol_cargo: {
            required,
          },
          motivo: {
            required,
            minLength: minLength(10),
          },
          n_contrato: {
            required,
          },
          type_document: {
            required,
          },
          email_admin: {
            required,
          }
        },
      }
    }
  },

  watch: {
    'form.nombres'() {
      this.modificacion('nombres', "Nombres");
    },
    'form.apellidos'() {
      this.modificacion('apellidos', "Apellidos");
    },
    'form.email'() {
      this.modificacion('email', "Email");
    },
    'form.telefono'() {
      this.modificacion('telefono', "Teléfono");
    },
    'form.empresa'() {
      this.modificacion('empresa', "Empresa");
    },
    'form.rol_cargo'() {
      this.modificacion('rol_cargo', "Cargo");
    },
  },

  methods: {

    validateLines() {
      this.isValidSelection = this.form.lineas.length > 0 && !this.form.lineas.includes(0);
    },

    modificacion(campo, nombre) {
      if (this.alert) {
        const nuevo = this.form[campo] ? this.form[campo].toLowerCase() : "";
        campo = campo == "telefono" ? "cel" : campo == "rol_cargo" ? 'charge' : campo;
        const valorOriginal = this.datosOriginales[campo] ? this.datosOriginales[campo].toLowerCase() : "";
        if (nuevo !== valorOriginal && nuevo !== "") {
          const mensaje = `Modificación en ${nombre}`;
          if (!this.modificaciones.includes(mensaje)) {
            this.modificaciones.push(mensaje);
          }
        } else {
          this.modificaciones = this.modificaciones.filter(mod => !mod.startsWith(`Modificación en ${nombre}`));
        }
      } else {
        this.modificaciones = [];
      }

    },

    async init() {
      this.accion = this.$route.params.accion;
      if (this.$route.params.accion == "Editar") {
        this.form = this.$route.params.data_edit;
        if (this.form.linea_negocio_id) {
          this.form.lineas = this.form.linea_negocio_id.split(",").map(Number);
        } else {
          this.form.lineas = [];
        }
        await this.documentValidacion();
        if (this.form.empresa_tercero) {
          this.rd = 'no';
        }
        this.metodo = "PUT";
      } else {
        this.metodo = "POST";
        this.form.estado = 1;
        this.form.lineas = [];
      }
      this.validateLines();
    },

    validarLetra(palabra, opcion) {
      if (palabra.length > 0) {
        var ascii = palabra.toUpperCase().charCodeAt(palabra.length - 1);
        if (
          !(
            (ascii > 64 && ascii < 91) ||
            ascii == 32 ||
            ascii == 193 ||
            ascii == 201 ||
            ascii == 205 ||
            ascii == 211 ||
            ascii == 218
          )
        ) {
          if (opcion == 1) {
            this.form.name = palabra.slice(0, -1);
          }
        }
      }
    },

    getTiposIdentificacion() {
      axios.get("/api/lista/6").then((response) => {
        this.listasForms.tipo_identificacion = response.data;
      });
    },

    getLineas() {
      axios.get("/api/lista/209").then((response) => {
        this.listasForms.lineas = response.data;
      });
    },

    checkRd() {
      this.form.empresa = null;
      this.form.empresa_tercero = null;
    },

    emailValidacion() {
      this.cargando = true;
      let email = this.form.email.split("@");
      axios({
        method: "GET",
        url: "/api/admin/usuariosFrontera/emailAdminValidar",
        params: { email: this.form.email, n_documento: this.form.n_documento }
      }).then((response) => {
        this.validar = response.data.msg;
        this.cargando = false;
        if (this.validar == "si") {
          this.form.user = this.validar;
          this.form.email = null;
          this.$swal({
            icon: "error",
            title: `Este correo ya se encuentra asignado.`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      });
    },

    getFuncionarios() {
      axios.get("/api/admin/funcionarios/lista?admin_contrato=" + 1).then((response) => {
        this.listasForms.funcionarios = response.data;
        if (this.form.email_admin) {
          this.admin = this.listasForms.funcionarios.filter((element) => element.email == this.form.email_admin);
        }
      });
    },

    documentValidacion() {
      this.cargando = true;
      if (this.accion == "Crear") {
        this.limpiarForm();
      }
      axios({
        method: "GET",
        url: "/api/admin/usuariosFrontera/documentValidacion",
        params: { n_documento: this.form.n_documento }
      }).then((response) => {
        this.cargando = false;
        const user = response.data;
        if (user.exists) {
          this.alert = true;
          this.$swal({
            title: `Esta solicitud es para actualización de datos.`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 0,
            timerProgressBar: true,
            background: "#001871",
            customClass: {
              container: 'my-swal-container',
              title: 'my-swal-title',
            },
          });
          const { nombres, apellidos } = this.separarNames(user.user.name);
          const linea_negocio_id = user.user.lineas_negocio.length > 0 ? user.user.lineas_negocio.map(item => item.id).join(',') : '';
          const empresa = user.user.empresas.length > 0 ? user.user.empresas[0].razon_social : '';
          this.datosOriginales = { ...user.user, nombres, apellidos, linea_negocio_id, empresa };
          this.actualizarSugerencias();
          this.modificacion('nombres', 'Nombres');
          this.modificacion('apellidos', 'Apellidos');
          this.modificacion('email', 'Email');
          this.modificacion('telefono', 'Teléfono');
          this.modificacion('empresa', 'Empresa');
          this.modificacion('rol_cargo', 'Cargo');
        } else {
          this.alert = false;
          this.datosOriginales = [];
          if (this.accion == "Crear") {
            this.limpiarForm();
          }
          this.$swal({
            title: `Creación nuevo usuario`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 0,
            timerProgressBar: true,
            background: "#001871",
            customClass: {
              container: 'my-swal-container',
              title: 'my-swal-title',
            },
          });
        }
      });
    },

    confirmationModal() {
      if (this.alert) {
        let modificacionesTexto = this.modificaciones.length > 0 ? '<div><b>¿Está seguro de realizar las siguientes modificaciones?</b></div>' : '';
        this.modificaciones.forEach(element => {
          (modificacionesTexto += `<div><small>${element}</small></div>`)
        });
        this.$swal({
          title: "ADVERTENCIA.",
          html: modificacionesTexto,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sí, actualizar",
          cancelButtonText: "Cancelar",
        }).then((result) => {
          if (result.isConfirmed) {
            this.save();
          }
        });
      } else {
        this.save();
      }
    },

    limpiarForm() {
      this.form.nombres = '';
      this.form.apellidos = '';
      this.form.email = '';
      this.form.telefono = '';
      this.form.email_admin = '';
      this.form.empresa = '';
      this.form.empresa_tercero = '';
      this.form.rol_cargo = '';
      this.form.n_contrato = '';
      this.form.motivo = '';
      this.lineas_negocios = [];
      this.admin = [];
    },

    separarNames(nombreCompleto) {
      const partes = nombreCompleto.trim().split(" ");

      let nombres = "";
      let apellidos = "";

      if (partes.length === 4) {
        nombres = `${partes[0]} ${partes[1]}`;
        apellidos = `${partes[2]} ${partes[3]}`;
      } else if (partes.length === 3) {
        if (esApellido(partes[2], partes[0], partes[1])) {
          nombres = `${partes[0]} ${partes[1]}`;
          apellidos = partes[2];
        } else {
          nombres = partes[0];
          apellidos = `${partes[1]} ${partes[2]}`;

        }
      } else if (partes.length === 2) {
        nombres = partes[0];
        apellidos = partes[1];
      } else {
        nombres = partes.slice(0, 2).join(" ");
        apellidos = partes.slice(2).join(" ");
      }

      return { nombres, apellidos };

      function esApellido(terceraPalabra, primeraPalabra, segundaPalabra) {
        const longitudTercera = terceraPalabra.length;
        const longitudPrimera = primeraPalabra.length;
        const longitudSegunda = segundaPalabra.length;
        return longitudTercera >= Math.max(longitudPrimera, longitudSegunda);
      }
    },

    actualizarSugerencias() {
      if (this.campoActivo === 'nombres') {
        this.sugerencias = [{ nombre: this.datosOriginales.nombres }];
      } else if (this.campoActivo === 'apellidos') {
        this.sugerencias = [{ apellido: this.datosOriginales.apellidos }];
      } else if (this.campoActivo === 'email') {
        this.sugerencias = [{ email: this.datosOriginales.email }];
      } else if (this.campoActivo === 'telefono') {
        this.sugerencias = [{ telefono: this.datosOriginales.cel }];
      } else if (this.campoActivo === 'rol_cargo') {
        this.sugerencias = [{ rol_cargo: this.datosOriginales.charge }];
      }
    },

    filtrarSugerencias(query, campo) {
      if (campo === 'nombres') {
        return this.sugerencias.filter(sugerencia =>
          sugerencia.nombre && sugerencia.nombre.toLowerCase().includes(query.toLowerCase())
        );
      } else if (campo === 'apellidos') {
        return this.sugerencias.filter(sugerencia =>
          sugerencia.apellido && sugerencia.apellido.toLowerCase().includes(query.toLowerCase())
        );
      } else if (campo === 'email') {
        return this.sugerencias.filter(sugerencia =>
          sugerencia.email && sugerencia.email.toLowerCase().includes(query.toLowerCase())
        );
      } else if (campo === 'telefono') {
        return this.sugerencias.filter(sugerencia =>
          sugerencia.telefono && sugerencia.telefono.toLowerCase().includes(query.toLowerCase())
        );
      } else if (campo === 'rol_cargo') {
        return this.sugerencias.filter(sugerencia =>
          sugerencia.rol_cargo && sugerencia.rol_cargo.toLowerCase().includes(query.toLowerCase())
        );
      }
      return [];
    },

    seleccionarSugerencia(campo, valor) {
      this.form[campo] = valor;
      this.sugerencias = [];
    },

    convertirArray() {
      let ids = this.form.linea_negocio_id.split(',');
      this.lineas_negocios = this.listasForms.linea_negocios.filter(ln => { return ids.includes(String(ln.id)) });
    },

    convertir() {
      this.form.linea_negocio_id = this.lineas_negocios.map(item => item.id).join(',');
    },

    selectAdmin() {
      this.form.email_admin = this.admin ? this.admin.email : null;
    },

    save() {
      this.form.linea_negocio_id = this.form.lineas.join(',');
      this.form.modificaciones = this.modificaciones.join(', ');
      if (!this.$v.form.$invalid) {
        this.cargando = true;
        axios({
          method: this.metodo,
          url: "/api/admin/usuariosFrontera",
          data: this.form,
        })
          .then((response) => {
            this.cargando = false;
            this.$swal({
              icon: "success",
              title:
                "la solicitud se creo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            return this.$router.push({
              name: "/Admin/UsuariosFrontera",
            });
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title:
                "Ha ocurrido un error, por favor intente la accion nuevamente.",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    back() {
      this.$swal({
        title: ``,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1,
        timerProgressBar: true,
        background: "#001871",
        customClass: {
          container: 'my-swal-container',
          title: 'my-swal-title',
        },
      });
      return this.$router.replace("/Admin/UsuariosFrontera");
    },
  },

  async mounted() {
    await this.init();
    await this.getTiposIdentificacion();
    await this.getLineas();
    await this.getFuncionarios();
  },
};

</script>
<style>
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

.ir-arriba {
  position: fixed;
  top: 100px;
  right: 20px;
  font-size: 24px;
  color: #000;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.well {
  min-height: 20px;
  padding: 14px;
  margin-bottom: 10px;
  border: 2px solid #001871;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.my-swal-container {
  margin-top: 50px;
}

.my-swal-title {
  color: #ffffff;
}

.well-legend {
  display: block;
  font-size: 20px;
  width: auto;
  min-width: 300px;
  padding: 2px 7px 2px 5px;
  line-height: unset;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.form-group {
  position: relative;
}

.suggestions-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
}

.suggestions-list {
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  /* Color de fondo blanco */
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  z-index: 1000;
  width: 100%;
}

.suggestions-list li {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}

.suggestions-list li:hover {
  background-color: #f0f0f0;
  /* Color de fondo al pasar el ratón */
}

.suggestions-list li.selected {
  background-color: #e0e0e0;
  /* Color de fondo cuando el ítem está seleccionado */
}
</style>